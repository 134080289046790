import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  innerWidth,
  mainWhite,
  screen,
  moveBgColor,
} from "../components/common/variables";
import StyledButton from "../components/styled-button";
import IconMountain from "../images/svg/mountain.svg";
import Logo from "../images/svg/logo-brand.svg";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  color: ${mainWhite};
  background-image: linear-gradient(45deg, #11447b, #1e3e60, #151d27);
  background-size: 370%;
  color: ${mainWhite};
  animation: ${moveBgColor} 10s infinite alternate;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: -180px auto 0 auto;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh + 180px);
    @media ${screen.xsmall} {
      padding: 0 48px 0 120px;
    }
    @media ${screen.medium} {
      padding: 0 0 0 120px;
    }

    .logo {
      svg {
        width: 117px;
      }
    }

    .heading {
      font-size: 2.8rem;
      font-weight: 500;
      max-width: 700px;
      margin: 30px 0 44px 0;
    }
  }

  .decor {
    position: absolute;
    bottom: -3px;
    right: 0;

    svg {
      width: 600px;
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Demo" />
      <Wrapper>
        <div className="inner-wrapper">
          <div className="logo">
            <Logo />
          </div>
          <h1 className="heading">
            Are your applications ready for Windows Virtual Desktop?
          </h1>
          <StyledButton to="/goals">FIND OUT MORE</StyledButton>
        </div>

        <div className="decor">
          <IconMountain />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
